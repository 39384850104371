.parlans-message {
	background-color: #465cc0;
	width: 100%;
	margin-bottom: 10px;
	border-radius: 5px;
	padding: 10px;
	color: #fff;

	p {
		font-size: 1rem;
	}

	.parlans-message-user {
		font-size: 0.8rem;
	}

	.sticker {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: start;

		> * {
			height: 10vh;
			width: auto !important;
		}
		
		&:hover {
			opacity: 1 !important;
			cursor: unset !important;
		}
	}
}
