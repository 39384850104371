.participants {
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    
    .participant {
        width: 100%;
        padding: 15px;
        background-color: #465cc0;
        color: #fff;
        border-radius: 5px;
    }
}