.message-stickers-container {
	max-height: 1000px;
	transition: max-height 0.7s ease-out;
	width: 100%;

	display: flex;
	flex-direction: column;

	&.hidden {
		max-height: 0px;
	}

	.message-sticker-tabs {
		display: flex;
		justify-content: space-evenly;
		width: 100%;

		> li {
			list-style-type: none;
			cursor: pointer;
			flex-grow: 1;
			text-align: center;
			border-color: #7991ff;
			border-bottom: 2px solid #7991ff;
			color: #ffffff;
			padding: 0.5rem 0rem;

			&:hover {
				opacity: 0.8;
			}
		}

		.is-selected {
			background-color: #7991ff;
			border-bottom: 2px solid #ffffff;
			background-color: #7991ff;
			transition-duration: 0.5s;
		}
	}

	.message-stickers {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		width: 100%;
		grid-gap: 1rem;
		max-height: 350px;
		min-height: 350px;
		padding: 10px;
		overflow-y: auto;
		background-color: #7991ff;

		&:not(.is-selected) {
			display: none !important;
		}
	}
}
