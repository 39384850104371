.component-ui-sidebar {
	height: 100vh;

	.sidebar-tabs {
		display: flex;
		flex-direction: column;
		height: 100%;


		// TODO: Que los ul tengan heigth limitada pero que los .sidebar-tab-panel solo llenen el resto de la pantalla (no heigth 100%)
		> ul {
			height: 5%;
			display: flex;
			justify-content: space-evenly;

			li {
				list-style-type: none;
				cursor: pointer;
				flex-grow: 1;
				text-align: center;
				background-color: #7991ff;
				border-color: #7991ff;
				border-bottom: 2px solid #7991ff;
				color: #ffffff;
				padding: 0.5rem 0rem;

				&:hover {
					opacity: 0.8;
				}
			}
		}

		.selected-tab {
			background-color: #7991ff;
			border-bottom: 2px solid #ffffff;
			background-color: #7991ff;
			transition-duration: 0.5s;
		}
		.sidebar-tab-panel {
			display: none;

			&.selected-panel {
				display: block !important;
				height: 95%;
			}
		}
	}
}
